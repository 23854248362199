var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice" },
    [
      _c("Header", { attrs: { title: "消息", back: true } }),
      _c(
        "div",
        { staticClass: "box-items msg-box" },
        _vm._l(_vm.msgList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "flex-box",
              on: {
                click: function ($event) {
                  return _vm.msgDetail(item.type)
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("div", { staticClass: "flex-box title-box" }, [
                  _c(
                    "div",
                    { staticClass: "item-title theme-color" },
                    [
                      !item.read
                        ? _c(
                            "el-badge",
                            { staticClass: "item", attrs: { "is-dot": "" } },
                            [_vm._v(_vm._s(_vm._f("filterPeriods")(item.type)))]
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm._f("filterPeriods")(item.type))),
                          ]),
                    ],
                    1
                  ),
                  item.addtime
                    ? _c("div", { staticClass: "item-date" }, [
                        _vm._v(_vm._s(_vm.formatDate(item.addtime))),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "item-content" }, [
                  _vm._v(_vm._s(item.content)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }