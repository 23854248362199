<template>
  <div class="notice">
    <Header :title="'消息'" :back="true"/>
    <div class="box-items msg-box">
      <div class="flex-box" v-for="(item,index) in msgList" :key="index" @click="msgDetail(item.type)">
        <!-- <div><img :src="require('@/assets/img/'+(index+1)+'.png')"/></div> -->
        <div style="width: 100%;">
          <div class="flex-box title-box">
            <div class="item-title theme-color">
              <el-badge v-if="!item.read" is-dot class="item">{{item.type | filterPeriods}}</el-badge>
              <span v-else>{{item.type | filterPeriods}}</span>
            </div>
            <div v-if="item.addtime" class="item-date">{{formatDate(item.addtime)}}</div>
          </div>
          <div class="item-content">{{item.content}}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      num:0,
      msgList:[]
    }
  },
  filters:{
    filterPeriods(type){
      const statusDay ={
        deal:'交易动态',
        cash:'充值转出',
        activity:'优惠活动',
        notice:'系统公告'
      }
      return statusDay[type]
    }
  },
  created(){
    this.getMessage()
  },
  methods:{
    getMessage(){
      this.$store.dispatch('auth/getMessage',{params:{form:'snap'}}).then(res => {
        this.msgList=res.messages
      })
    },
    msgDetail(type){
      this.$router.push({path:'noticedetail',query:{type:type}})
      // this.$store.dispatch('auth/getMessage',{params:{type:type}}).then(res => {
      //   this.msgList=res.messages
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.notice{
  .msg-box{
    margin-top: 2.5%;
    >:not(:last-child){
      border-bottom:1px solid #e4e4e4;
      padding-bottom:1rem;
    }
    >.flex-box{
      flex-shrink: 0;
      align-items: center;
      margin-bottom:1rem;
     
     
      >:first-child{
        text-align: center;
        margin-right: 3%;
        width: 12%;
        
      }
      .title-box{

        // background: red;
        align-items: center;
        justify-content: space-between;
      }
      .item-title{
        font-size: 1rem;
        // line-height: 2;
      }
      .item-date{
        color: #464646;
        font-size: .8rem;
      }
      .item-content{
        text-align: left;
        margin-top:2%;
        font-size: .9rem;
        color: #464646;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis
        
      }
    }
  }
}
</style>